import * as React from 'react';
import BGEALogo from '../images/bgea-logo-white.svg';
import './SiteFooter.scss';
import { useLocation } from '@reach/router';
// import { useState } from 'react';
// import navigation from '../constants/navigation.js';
// import Facebook from "../images/facebook.svg"
// import X from "../images/x.svg"
// import YouTube from "../images/youtube.svg"
// import Instagram from "../images/instagram.svg"

// import CookieConsent, {
//   Cookies,
//   resetCookieConsentValue,
//   getCookieConsentValue,
// } from 'react-cookie-consent';

const NPOText = {
  'en': 'BGEA is a registered 501(c)(3) non-profit organization.',
  'es': 'BGEA es una organización sin ánimo de lucro registrada como 501(c)(3).',
};

const contactLink = {
  'en': 'https://billygraham.org/about/contact/',
  'es': 'https://es.billygraham.org/contacto/',
};

const contactText = {
  'en': 'Contact',
  'es': 'Contacto',
};

const phoneLocal = {
  'en': 'Local',
  'es': 'Número local',
}

const phoneText = {
  'en': 'Toll Free',
  'es': 'Llame sin costo',
};

const SiteFooter = () => {
  // //This const checks if the user is in a browser, this allows build to pass
  // const isBrowser = typeof window !== "undefined"
  // if(isBrowser)
  //   bgParser('/' ,'BA220YHL0')
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];
  // const showCookieConsent = true;
  // const [show, setShow] = useState(false);

  // function checkCookie() {
    // const gdpr = getCookieConsentValue('gatsby-gdpr-google-tagmanager') === 'true';
    // if (gdpr === true) {
    //   return (
    //     <p>
    //       <button
    //         className="button is-small decline-cookies withdrawButton"
    //         onClick={() => {
    //           resetCookieConsentValue('gatsby-gdpr-google-tagmanager');
    //           setShow(!show);
    //         }}
    //       >
    //         {currentPathLanguage === 'en'
    //           ? 'Withdraw Cookie Consent'
    //           : 'Retirar consentimento de cookies'}
    //       </button>
    //     </p>
    //   );
    // } else {
    //   return <p className="footer-spacing"></p>;
    // }
  // }

  return (
    <footer>
      <section className="widget_text widget custom_html-3 widget_custom_html">
          <div className="columns">
            <div className="column logo">
              <a
                href="https://billygraham.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={BGEALogo}
                  alt="Billy Graham Evangelistic Association"
                  width="180"
                />
              </a>
            </div>
            <div className="column contact">
              <h2>{contactText[currentPathLanguage]}</h2>
              <p>Billy Graham Evangelistic Association</p>
              <p>1 Billy Graham Parkway</p>
              <p>Charlotte, NC 28201</p>
              <p>{phoneLocal[currentPathLanguage]}: 704-401-2432</p>
              <p>{phoneText[currentPathLanguage]}: 1-877-247-2426</p>
            </div>
            {location.pathname.split('/')[1] === 'en' ? (
            <>
            <div className="column social">
              <h2>Follow</h2>
              <a href="https://www.facebook.com/BillyGrahamEvangelisticAssociation/">Facebook</a>
              <a href="https://www.instagram.com/bgea/">Instagram</a>
              <a href="https://twitter.com/bgea">X</a>
              <a href="https://www.youtube.com/BillyGraham">YouTube</a>
              <a href="https://tiktok.com/@bgeaofficial">TikTok</a>
              <a href="https://media.billygraham.org/">Press & Media</a>
            </div>
            <div className="column legal">
              <h2>Legal</h2>
              <a href="https://billygraham.org/privacy-policy/">Privacy</a>
              <a href="https://billygraham.org/legal-permissions/">Permissions</a>       
              <a href="https://billygraham.org/solicitation-disclosure-statement/">Solicitation</a>
            </div>
            </> 
            ) : ( 
            <>
            <div className="column social">
              <h2>Redes sociales</h2>
              <a href="https://www.facebook.com/BillyGrahamEvangelisticAssociationEspanol">Facebook</a>
              <a href="https://www.instagram.com/bgeaespanol/">Instagram</a>
              <a href="https://twitter.com/BGEAespanol">X</a>
              <a href="https://www.youtube.com/@billygrahamespanol">YouTube</a>
            </div>
            <div className="column legal">
              <h2>Legal</h2>
              <a href="https://es.billygraham.org/privacidad/">Privacidad</a>     
              <a href="https://billygraham.org/aviso-sobre-donaciones/">Solicitation</a>
            </div>
            </>
            )}
          </div>
          <div className='columns bottom-footer mt-6'>
            <div className='column is-one-quarter'>
              <p>© {new Date().getFullYear()} BGEA</p>
            </div>
            <div className='column'>
            <p>{NPOText[currentPathLanguage]}</p>
            </div>
            <div className='column is-one-quarter'>
              <a href={contactLink[currentPathLanguage]}>{contactText[currentPathLanguage]}</a>
            </div>
          </div>
      </section>
    </footer>
  );
};

export default SiteFooter;
